import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import Select, { arrayObjToObj, countryData } from '../../Components/UI/Select/Select'
import { getIndustry, getMCC } from '../../Utils/generalUtil'
import ApiError from '../../Components/UI/Alert/ApiError'
import { setGAEvent } from '../../Utils/googleAnalytics'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Checkbox from '../../Components/UI/Checkbox/Checkbox'
import Loader from '../../Components/UI/Loader/Loader'
import Step from '../../Components/Step/Step'

class Screen3 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            showLoader: false,
            redirect: false,
            gotoHome: false,
            mra3: true,
            needCard: false,
            bankIso: Cookies.get("pmx_iso"),
            naceList: [],
            mccList: [],
            mra1: "200",
            mra2: "50",
            mra31: "",
            iban: "",
            naceCode: "",
            mccCode: "",
            redirectTo: "",
        }
    }
    componentDidMount() {
        if (Cookies.get("pmx_token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        } else {
            setGAEvent("page_view","Busioness Info 3","Step 3")
            const { t } = this.props
            const naceList = getIndustry(t)
            const mccList = getMCC(t)
            this.setState({ naceList: naceList, mccList: mccList, showLoader: false })
        }
    }

    validateForm = () => {
        const { t } = this.props
        if (this.state.mra1.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_mra1') })
        } else if (this.state.mra2.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_mra2') })
        } else if (this.state.naceCode.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_nace') })
        } else if (this.state.mccCode.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_mcc') })
        } else if (!this.state.mra3 && (this.state.mra31.length === 0) ) {
                this.setState({ showErrModal: true, modalErr: t('ERR_mra3') })
        } else {
            const savedData = Cookies.get("pmx_progress")
            const jsonObj = JSON.parse(savedData)
            const companyInfo = {
                companyType: jsonObj.companyType,
                companyName: jsonObj.companyName,
                companyWeb: (jsonObj.companyWeb === undefined) ? "" : jsonObj.companyWeb,
                companyTradeName: (jsonObj.companyTradeName === undefined) ? "" : jsonObj.companyTradeName,
                creditSafeId: (jsonObj.creditSafeId === undefined) ? "N/A" : jsonObj.creditSafeId,
                registeredIso: jsonObj.registeredIso,
                registeredNumber: (jsonObj.registeredNumber === undefined) ? "" : jsonObj.registeredNumber,
                registerDate: jsonObj.registerDate,
                vatTax: jsonObj.vatTax,
                registeredAddress1: (jsonObj.registeredAddress1 === undefined) ? "" : jsonObj.registeredAddress1,
                registeredCity: (jsonObj.registeredCity === undefined) ? "" : jsonObj.registeredCity,
                registeredAddrIso: (jsonObj.registeredAddrIso === undefined) ? "" : jsonObj.registeredAddrIso,
                mra1: this.state.mra1,
                mra2: this.state.mra2,
                mra3: (this.state.mra3) ? '1day' : this.state.mra31,
                bankLocation: this.state.bankIso,
                bankIban: this.state.iban,
                naceCode: this.state.naceCode,
                mccCode: this.state.mccCode,
                needCard: this.state.needCard,
                shareHolder: (jsonObj.shareHolder === undefined) ? "" : jsonObj.shareHolder,
                page: '/business-address'
            }
            Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
            this.setState({ redirect: true, redirectTo: "/business-address" })
        }
    }
    goBack = () => {
        const savedData = Cookies.get("pmx_progress")
        const jsonObj = JSON.parse(savedData)
        const companyInfo = {
            companyType: jsonObj.companyType,
            companyName: jsonObj.companyName,
            companyWeb: (jsonObj.companyWeb === undefined) ? "" : jsonObj.companyWeb,
            companyTradeName: (jsonObj.companyTradeName === undefined) ? "" : jsonObj.companyTradeName,
            creditSafeId: (jsonObj.creditSafeId === undefined) ? "N/A" : jsonObj.creditSafeId,
            registeredIso: jsonObj.registeredIso,
            registeredNumber: (jsonObj.registeredNumber === undefined) ? "" : jsonObj.registeredNumber,
            registerDate: jsonObj.registerDate,
            vatTax: jsonObj.vatTax,
            mra1: (jsonObj.mra1 === undefined) ? "" : jsonObj.mra1,
            mra2: (jsonObj.mra2 === undefined) ? "" : jsonObj.mra2,
            mra3: (jsonObj.mra3 === undefined) ? "" : jsonObj.mra3,
            bankLocation: (jsonObj.bankLocation === undefined) ? "" : jsonObj.bankLocation,
            bankIban: (jsonObj.bankIban === undefined) ? "" : jsonObj.bankIban,
            naceCode: (jsonObj.naceCode === undefined) ? "" : jsonObj.naceCode,
            mccCode: (jsonObj.mccCode === undefined) ? "" : jsonObj.mccCode,
            needCard: (jsonObj.needCard === undefined) ? "No" : jsonObj.needCard,
            registeredAddress1: (jsonObj.registeredAddr1 === undefined) ? "" : jsonObj.registeredAddr1,
            registeredAddress2: (jsonObj.registeredAddr2 === undefined) ? "" : jsonObj.registeredAddr2,
            registeredCity: (jsonObj.registeredCity === undefined) ? "" : jsonObj.registeredCity,
            registeredZip: (jsonObj.registeredZip === undefined) ? "" : jsonObj.registeredZip,
            registeredAddrIso: (jsonObj.registeredAddrIso === undefined) ? "" : jsonObj.registeredAddrIso,
            operAddress1: (jsonObj.operAddress1 === undefined) ? "" : jsonObj.operAddress1,
            operAddress2: (jsonObj.operAddress2 === undefined) ? "" : jsonObj.operAddress2,
            operCity: (jsonObj.operCity === undefined) ? "" : jsonObj.operCity,
            operZip: (jsonObj.operZip === undefined) ? "" : jsonObj.operZip,
            operAddrIso: (jsonObj.operAddrIso === undefined) ? "" : jsonObj.operAddrIso,
            shareHolder: (jsonObj.shareHolder === undefined) ? "" : jsonObj.shareHolder,
            page: '/business-profile'
        }
        Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
        this.setState({ redirect: true, redirectTo: "/business-profile" })
    }
    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            } else {
                return (<Navigate to={this.state.redirectTo} replace={true} />)
            }
        }
        const { t } = this.props
        return (
            <Layout>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1" btnName={t('BTN_close')}>
                    <div className="p-3">
                        {this.state.modalErr}
                    </div>
                </ApiError>
                {this.state.showLoader ? (
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                        <Loader />
                    </div>
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                        <div className='W100 mt-4'>
                            <Step active={2} step={2} />
                        </div>
                        <div className='W100 pt-2 ps-4 pe-4'>
                            <h1 className='poppins-extrabold'>{t('BP_title3_h1')}</h1>
                            <p className='poppins-regular mt-4 mb-4'>{t('BP_info3_p')}</p>
                            <div>
                                <label className="form-label poppins-regular ">{t('LBL_mra_1')}</label>
                                <input type="number" className="form-control" value={this.state.mra1} onChange={(e) => this.setState({ mra1: e.target.value })} />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_mra_2')}</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">€</span>
                                    <input type="text" className="form-control" value={this.state.mra2} onChange={(e) => this.setState({ mra2: e.target.value })} />
                                </div>
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_cra_bank_location')}</label>
                                <Select
                                    styles="form-control W550"
                                    clicked={(value) => { this.setState({ bankIso: value }) }}
                                    selectedItem={this.state.bankIso}
                                    options={countryData()}
                                />
                            </div>
                            <div className='mt-4' style={{display:'none'}}>
                                <label className="form-label poppins-regular ">{t('LBL_iban')}</label>
                                <input type="text" className="form-control" value={this.state.iban} onChange={(e) => this.setState({ iban: e.target.value })} />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_nace')}</label>
                                <Select
                                    styles="form-control"
                                    clicked={(value) => { this.setState({ naceCode: value, mccCode: value }) }}
                                    selectedItem={this.state.naceCode}
                                    options={arrayObjToObj(this.state.naceList)}
                                />
                            </div>
                            <div className='mt-4' style={{display:'none'}}>
                                <label className="form-label poppins-regular ">{t('LBL_mcc')}</label>
                                <Select
                                    styles="form-control"
                                    clicked={(value) => { this.setState({ mccCode: value }) }}
                                    selectedItem={this.state.mccCode}
                                    options={arrayObjToObj(this.state.mccList)}
                                />
                            </div>
                            <div className='mt-4'>
                                <Checkbox clicked={(value) => { this.setState({ mra3: value }) }} checked={this.state.mra3}>
                                    {t('LBL_mra3')}
                                </Checkbox>
                            </div>
                            {!this.state.mra3 ? (
                                <div className='mt-4'>
                                    <label className="form-label poppins-regular ">{t('LBL_mra3-1')}</label>
                                    <input type="number" className="form-control" value={this.state.mra31} onChange={(e) => this.setState({ mra31: e.target.value })} />
                                </div>
                            ):null}
                            <div className='mt-4'>
                                <Checkbox clicked={(value) => { this.setState({ needCard: value }) }} checked={this.state.needCard}>
                                    {t('LBL_want_card')}
                                </Checkbox>
                            </div>
                            <Button clicked={() => this.validateForm()} btnType={"mt-5 ButtonLogin"} type="submit">{t('BTN_next')}</Button>
                            <p className='linkBtn poppins-bold pointer' onClick={() => this.goBack()} >{t('LINK_back')}</p>
                        </div>
                    </div>
                )}
            </Layout>
        )
    }
}

export default withTranslation()(Screen3)