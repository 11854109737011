import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import axiosPersona from '../../Utils/personaAxios'
import {setGAEvent} from '../../Utils/googleAnalytics'
import Select, { countryData } from '../../Components/UI/Select/Select'
import ApiError from '../../Components/UI/Alert/ApiError'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Loader from '../../Components/UI/Loader/Loader'
import Step from '../../Components/Step/Step'
import checkIco from '../../Assets/uboCheck.svg'
import editIco from '../../Assets/uboEdit.svg'
import deleteIco from '../../Assets/uboDelete.svg'
import addIco from '../../Assets/uboAdd.svg'

class ShareHolder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            showLoader: false,
            redirect: false,
            gotoHome: false,
            modalErr: "",
            uboFirstName: "",
            uboSurname: "",
            ubosHare: 0,
            uboId: 1,
            uboNationality: Cookies.get("pmx_iso"),
            uboResidency: Cookies.get("pmx_iso"),
            ubos: [],
            isEdit: 0,
            totalShare: 0
        }
    }
    componentDidMount() {
        setGAEvent("page_view","Shareholder screen","Shareholder Screen")
        if (Cookies.get("pmx_token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        } else {
            const savedData = Cookies.get("pmx_progress")
            if (savedData !== undefined) {
                const jsonObj = JSON.parse(savedData)
                if (jsonObj.shareHolder.length > 0) {
                    let uboList = this.state.ubos
                    let totalShare = this.state.totalShare
                    for (let i = 0; i < jsonObj.shareHolder.length; i++) {
                        let uboObject = {}
                        uboObject.id = i + 1
                        uboObject.uboFirstName = jsonObj.shareHolder[i].firstName
                        uboObject.uboSurname = jsonObj.shareHolder[i].lastName
                        uboObject.ubosHare = jsonObj.shareHolder[i].share
                        uboObject.uboResidency = Cookies.get("pmx_iso")
                        uboObject.uboNationality = Cookies.get("pmx_iso")
                        uboList.push(uboObject)
                        totalShare += parseFloat(jsonObj.shareHolder[i].share)
                    }
                    this.setState({ ubos: uboList, totalShare: totalShare, uboId: jsonObj.shareHolder.length + 1 })
                }
            }
        }
    }
    saveData = () => {
        const { t } = this.props;
        const { uboFirstName, uboSurname, ubosHare, ubos, isEdit, uboNationality, uboResidency, totalShare, uboId } = this.state;

        if (uboFirstName.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_ubo_firstName') });
        } else if (uboSurname.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_ubo_lastName') });
        } else if (ubosHare === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_ubo_share') });
        } else if (ubos.some(ubo => ubo.firstName === uboFirstName && ubo.uboSurname === uboSurname && ubo.id !== isEdit)) {
            this.setState({ showErrModal: true, modalErr: t('ERR_ubo_duplicateName') });
        } else {
            let newUboList = [];
            let newTotalShare = 0;
            if (isEdit > 0) {
                for (let i = 0; i < ubos.length; i++) {
                    if (ubos[i].id === isEdit) {
                        let uboObject = {
                            id: isEdit,
                            uboFirstName: uboFirstName,
                            uboSurname: uboSurname,
                            ubosHare: parseFloat(ubosHare),
                            uboResidency: uboResidency,
                            uboNationality: uboNationality,
                        };
                        newUboList.push(uboObject);
                        newTotalShare += parseFloat(ubosHare);
                    } else {
                        let uboObject = {
                            id: ubos[i].id,
                            uboFirstName: ubos[i].uboFirstName,
                            uboSurname: ubos[i].uboSurname,
                            ubosHare: parseFloat(ubos[i].ubosHare),
                            uboResidency: ubos[i].uboResidency,
                            uboNationality: ubos[i].uboNationality
                        };
                        newUboList.push(uboObject);
                        newTotalShare += parseFloat(ubos[i].share);
                    }
                }
                this.setState({
                    ubos: newUboList,
                    totalShare: newTotalShare,
                    isEdit: 0,
                    uboFirstName: "",
                    uboSurname: "",
                    ubosHare: 0,
                    uboResidency: Cookies.get("pmx_iso"),
                    uboNationality: Cookies.get("pmx_iso")

                });
            } else {
                let newTotalShare = totalShare + parseFloat(ubosHare);
                if (newTotalShare > 100) {
                    this.setState({ showErrModal: true, modalErr: t('ERR_ubo_share100') });
                } else {
                    let uboObject = {
                        id: uboId,
                        uboFirstName: uboFirstName,
                        uboSurname: uboSurname,
                        ubosHare: parseFloat(ubosHare),
                        uboResidency: uboResidency,
                        uboNationality: uboNationality
                    };
                    ubos.push(uboObject);
                    this.setState({
                        ubos: ubos,
                        totalShare: newTotalShare,
                        isEdit: 0,
                        uboId: uboId + 1
                    });
                }
            }
            this.setState({ isEdit: 0, uboFirstName: "", uboSurname: "", ubosHare: 0 });
        }
    }

    editUbo = (id) => {
        for (let i = 0; i < this.state.ubos.length; i++) {
            if (this.state.ubos[i].id === id) {
                this.setState({
                    isEdit: this.state.ubos[i].id,
                    uboFirstName: this.state.ubos[i].uboFirstName,
                    uboSurname: this.state.ubos[i].uboSurname,
                    ubosHare: this.state.ubos[i].ubosHare,
                    uboResidency: this.state.ubos[i].uboResidency,
                    uboNationality: this.state.ubos[i].uboNationality
                })
            }
        }
    }

    deleteUbo = (id) => {
        const deletedUbo = this.state.ubos.find(ubo => ubo.id === id);

        this.setState((prevState) => ({
            totalShare: prevState - deletedUbo.share,
            ubos: prevState.ubos.filter(ubo => ubo.id !== id)

        }));
    }

    validateForm = () => {
        const { t } = this.props;
        if (this.state.ubosHare === '100' || this.state.ubos.length > 0) {
            this.saveData()
            const savedData = Cookies.get("pmx_progress")
            const jsonObj = JSON.parse(savedData)
            const requestBody = {
                'entityID': jsonObj.entityId,
                'ubos': this.state.ubos
            };
            const config = {
                headers: {
                    Authorization: `Bearer ${Cookies.get("pmx_token")}`,
                    'Accept-Language': Cookies.get("pmx_lang")
                }
            }
            axiosPersona.post("UBO", requestBody, config)
                .then((res) => {
                    if (res.data.success) {
                        this.setState({ redirect: true })
                    }
                })
                .catch((err) => {
                    this.setState({ redirect: true, showErrModal: true })
                })
        } else {
            this.setState({ showErrModal: true, modalErr: t('ERR_ubo_save_add') });
        }
    }

    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            }
            return (<Navigate to='/business-success' replace={true} />)
        }
        const { t } = this.props
        let screenData = null
        if (this.state.ubos.length > 0) {
            screenData = (
                <>
                    <div className='d-flex justify-content-center W100'>
                        <div className='d-flex flex-column uboBox align-items-center mt-4 mb-4'>
                            {
                                this.state.ubos.map(item => (
                                    <div className="align-self-center uboRow d-flex flex-row justify-content-between" key={item.uboFirstName + item.uboSurname + item.ubosHare}>
                                        <span>
                                            <img src={checkIco} alt='completed' title='completed' style={{ height: '24px', marginRight: '10px' }} />
                                            <span className='pe-2 poppins-bold'>{item.uboFirstName} {item.uboSurname}</span>
                                            <span>{item.ubosHare}%</span>
                                        </span>
                                        <span>
                                            <img src={editIco} alt='Edit' title='Edit' style={{ height: '24px', marginRight: '15px', cursor: 'pointer' }} onClick={() => this.editUbo(item.id)} />
                                            <img src={deleteIco} alt='Delete' title='Delete' style={{ height: '24px', marginRight: '10px', cursor: 'pointer' }} onClick={() => this.deleteUbo(item.id)} />
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
            )
        }
        return (
            <Layout>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1" btnName={t('BTN_close')}>
                    <div className="p-3">
                        {this.state.modalErr}
                    </div>
                </ApiError>
                {this.state.showLoader ? (
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                        <Loader />
                    </div>
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                        <div className='W100 mt-4'>
                            <Step active={2} step={2} />
                        </div>
                        <div className='W100 pt-2 ps-4 pe-4'>
                            <h1 className='poppins-extrabold'>{t('SP_title_h1')}</h1>
                            <p className='poppins-regular mt-4 mb-4'>{t('SP_info_p')}</p>
                            {screenData}
                            <div>
                                <label className="form-label poppins-regular ">{t('LBL_ubo_first')}</label>
                                <input type="text" className="form-control" value={this.state.uboFirstName} onChange={(e) => this.setState({ uboFirstName: e.target.value })} />
                            </div>
                            <div>
                                <label className="form-label poppins-regular ">{t('LBL_ubo_last')}</label>
                                <input type="text" className="form-control" value={this.state.uboSurname} onChange={(e) => this.setState({ uboSurname: e.target.value })} />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_ubo_nationality')}</label>
                                <Select
                                    styles="form-control W550"
                                    clicked={(value) => { this.setState({ uboNationality: value }) }}
                                    selectedItem={this.state.uboNationality}
                                    options={countryData()}
                                />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_ubo_residency')}</label>
                                <Select
                                    styles="form-control W550"
                                    clicked={(value) => { this.setState({ uboResidency: value }) }}
                                    selectedItem={this.state.uboResidency}
                                    options={countryData()}
                                />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_ubo_share')}</label>
                                <input type="number" className="form-control" value={this.state.ubosHare} onChange={(e) => this.setState({ ubosHare: e.target.value })} />
                            </div>
                            <div className='mt-4 pointer' onClick={() => this.saveData()}>
                                <img src={addIco} alt='completed' title='completed' style={{ height: '24px', marginRight: '10px' }} /> {t('LBL_ubo_add')}
                            </div>
                            <Button
                                clicked={() => this.validateForm()}
                                btnType={"mt-5 ButtonLogin"}
                                type="submit"
                            >
                                {t('BTN_next')}
                            </Button>
                        </div>
                    </div>
                )}
            </Layout>
        )
    }
}

export default withTranslation()(ShareHolder)