import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cookies from 'js-cookie'

import localEn from './Locales/en.json'
import localEl from './Locales/el.json'
import localDe from './Locales/de.json'
import localIt from './Locales/it.json'
import localTr from './Locales/tr.json'

const resources = {
    en: {
        translation: localEn,
    },
    de: {
        translation: localDe,
    },
    tr: {
        translation: localTr,
    },
    it: {
        translation: localIt,
    },
    eel: {
        translation: localEl,
    }
}
Cookies.set("pmx_lang", navigator.language.slice(0, 2), { expires: 1 })
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: navigator.language.slice(0, 2),
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n