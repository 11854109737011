import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import {  useMsal } from '@azure/msal-react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { b2cPolicies } from '../Config/authConfig'
import Layout from '../Containers/Layout';
import Step from '../Components/Step/Step'
import {setGAEvent} from '../Utils/googleAnalytics'
import logo from '../Assets/logo.svg'
import Button from '../Components/UI/Button/Button'
import Loader from '../Components/UI/Loader/Loader'

const Home = () => {
    const { instance } = useMsal()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const { t } = useTranslation()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const promoCode = queryParams.get('code');
    if(promoCode !== null){
        Cookies.set('pmx_promoCode', promoCode);
    }
    const reseller = queryParams.get('ref');
    if(reseller !== null){
        Cookies.set('pmx_reseller', reseller);
    }
    const request1 = {
        authority: b2cPolicies.authorities.register.authority,
        scopes: [],
        prompt: 'login',
        redirectUri:process.env.REACT_APP_REDIRECT_HOMEURI
    }
    useEffect(() => {
        setGAEvent("page_view","Home","home Page Visit")
        async function getToken() {
            let request = {
                scopes: [b2cPolicies.scopes],
            }
            await instance.acquireTokenSilent(request).then(tokenResponse => {
                if (tokenResponse.accessToken.length > 0 ) {
                    Cookies.set("pmx_token", tokenResponse.accessToken, { expires: 1 })
                    navigate("/business-info")
                } else
                    setLoader(false)
            }).catch(async (err) => {
                setLoader(false)
            })
        }
        getToken()
    })
    const doLogin = async () => {
        setGAEvent("click","Home","Continue Signup Button")
        try {
            await instance.loginRedirect({redirectUri:process.env.REACT_APP_REDIRECT_BUSINESSURI})
            setLoader(true)
        } catch (err) {
            alert(err)
        }
    }
    const handleSignUp = async () => {
        setGAEvent("click","Home","Signup Button")
        await instance.loginRedirect(request1)
        setLoader(true)
    }
    return (
        <Layout>
            {loader ? (
                <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                    <Loader />
                </div>
            ) : (
                <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                    <div className='mt-3'>
                        <img src={logo} alt='Paymix SoftPOS' title='Paymix SoftPOS' className='logo' />
                    </div>
                    <div className='W100 mt-4'>
                        <Step active={1} step={1}/>
                    </div>
                    <div className='W100 pt-2 ps-4 pe-4'>
                        <h1 className='poppins-extrabold'>{t('HP_title_h1')}</h1>
                        <p className='poppins-regular mt-4 mb-4'>{t('HP_info_p')}</p>
                        <div className='infoBox'>
                            <p className='poppins-bold infoBoxStep'>01.</p>
                            <p className='poppins-regular infoBoxInfo'>{t('HP_infobox1_p')}</p>
                        </div>
                        <div className='infoBox'>
                            <p className='poppins-bold infoBoxStep'>02.</p>
                            <p className='poppins-regular infoBoxInfo'>{t('HP_infobox2_p')}</p>
                        </div>
                        <div className='infoBox'>
                            <p className='poppins-bold infoBoxStep'>03.</p>
                            <p className='poppins-regular infoBoxInfo'>{t('HP_infobox3_p')}</p>
                        </div>
                        <Button clicked={() => handleSignUp()} btnType={"mt-4 ButtonLogin"} type="submit">{t('BTN_signup')}</Button>
                        <p className='linkBtn poppins-bold' onClick={()=>doLogin()}>{t('LINK_login')}</p>
                    </div>
                </div>
            )}
        </Layout>
    )
}
export default Home