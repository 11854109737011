import React from 'react'
import './Step.css'
import checkBtn from '../../Assets/check.svg'

const Step = props => {
    return (
        <div className="stepper-wrapper">
            <div className={`stepper-item ${props.step >1 ? 'completed' : props.active > 1 ? 'active' : ''}`}>
                {(props.active > 1) ? (
                    <div className="step-counter"><img src={checkBtn} alt='completed' title='completed' style={{height:'40px'}} /></div>
                ) : (
                    <div className="step-counter">1</div>
                )}
            </div>
            <div className={`stepper-item ${props.step > 2 ? 'completed' : props.active === 2 ? 'active' : ''}`}>
                {(props.active > 2) ? (
                    <div className="step-counter"><img src={checkBtn} alt='completed' title='completed' style={{height:'40px'}} /></div>
                ) : (
                    <div className="step-counter">2</div>
                )}
            </div>
            <div className={`stepper-item ${props.active === 3 ? 'active' : ''}`}>
                {(props.active > 3) ? (
                    <div className="step-counter"><img src={checkBtn} alt='completed' title='completed' style={{height:'40px'}} /></div>
                ) : (
                    <div className="step-counter">3</div>
                )}
            </div>
        </div>
    )
}

export default Step