import React, { Component } from 'react'

import './Alert.css'

import Backdrop from '../Backdrop/Backdrop'
import Button from '../Button/Button'

class ApiError extends Component {
    render() {
        return (
            <React.Fragment>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
                <div
                    style={{
                        transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
                        opacity: this.props.show ? "1" : "0",
                        display: this.props.show ? "block" : "hidden"
                    }}
                    className={["Alert ", [this.props.modalType]].join(" ")}
                >  
                    {this.props.children}
                    <Button clicked={this.props.modalClosed} btnType={"mt-2 mb-2 smallBtn"} type="submit">{this.props.btnName}</Button>
                </div>
            </React.Fragment>
        );
    }
}

export default ApiError;