import React from 'react'

import "./Checkbox.css"

const Checkbox = props => {
    return (
        <div className="checkbox-wrapper-13">
            <input id="c1-13" type="checkbox" checked={props.checked} onChange={(e)=>props.clicked(e.target.checked)} />
            <label>{props.children}</label>
        </div>

    )
}

export default Checkbox