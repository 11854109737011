import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'

import { b2cPolicies } from '../Config/authConfig'
import Layout from '../Containers/Layout'
import Loader from '../Components/UI/Loader/Loader'

const PostLogin = () => {
    const { instance } = useMsal()
    const navigate = useNavigate()
    useEffect(() => {
        async function getToken() {
            let request = {
                scopes: [b2cPolicies.scopes],
            }
            await instance.acquireTokenSilent(request).then(tokenResponse => {
                if (tokenResponse.accessToken.length > 0 ) {
                    Cookies.set("pmx_token", tokenResponse.accessToken, { expires: 1 })
                    navigate("/business-info")
                }
            }).catch(async (err) => {
            })
        }
        getToken()
    })
    return (
        <Layout>
            <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                <Loader />
            </div>
        </Layout>
    )
}
export default PostLogin