import React, { Component } from 'react'
import Cookies from 'js-cookie'
import axios from 'axios'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { init } from 'getid-launcher'

import Select, { countryData } from '../../Components/UI/Select/Select'
import {findObjectByCategory, getCountryISO2} from '../../Utils/generalUtil'
import {setGAEvent} from '../../Utils/googleAnalytics'
import axiosPersona from '../../Utils/personaAxios'
import Layout from '../../Containers/Layout'
import ApiError from '../../Components/UI/Alert/ApiError'
import Button from '../../Components/UI/Button/Button'
import Checkbox from '../../Components/UI/Checkbox/Checkbox'
import Loader from '../../Components/UI/Loader/Loader'
import Step from '../../Components/Step/Step'

class Idverify extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            showLoader: false,
            redirect: false,
            gotoHome: false,
            isAuth: false,
            runIdVerify: false,
            modalErr: "",
            redirectTo: "/thankyou",
            addr1: "",
            addr2: "",
            city: "",
            zip: "",
            iso: Cookies.get("pmx_iso")
        }
    }
    componentDidMount() {
        setGAEvent("page_view","In ID Verify Screen","ID Verify Screen")
        if (Cookies.get("pmx_token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        }
        const getIdApplication = window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1
        )
        if (getIdApplication !== "idverify" && getIdApplication.length > 0) {
            this.getData(getIdApplication)
            this.setState({ showLoader: true })
        }
    }
    getIdConfig = {
        flowName: "SoftPosWeb",
        apiUrl: process.env.REACT_APP_GETID_SDK_URL,
        sdkKey: process.env.REACT_APP_GETID_SDK_KEY,
        containerId: "getid-component",
        injectCSS: '#getid-main .GiLocaleSelect {display: none !important}',
        styles: {
            '--gi-primary-text-color': '#1E053A',
            '--gi-primary-button-background-color': '#1E053A'
        },
        onComplete({ applicationId }) {
            window.location.href = window.location.href + "/" + applicationId
        },
        onFail({ code, message }) {
        },
    }
    setAddress = (value) => {
        if (value) {
            const savedData = Cookies.get("pmx_progress")
            if (savedData !== undefined) {
                const jsonObj = JSON.parse(savedData)
                this.setState({
                    addr1: (jsonObj.registeredAddress1 === undefined) ? '' : jsonObj.registeredAddress1,
                    addr2: (jsonObj.registeredAddress2 === undefined) ? '' : jsonObj.registeredAddress2,
                    city: (jsonObj.registeredCity === undefined) ? '' : jsonObj.registeredCity,
                    iso: (jsonObj.registeredAddrIso === undefined) ? '' : jsonObj.registeredAddrIso,
                    zip: (jsonObj.registeredZip === undefined) ? '' : jsonObj.registeredZip,
                })
            }
        } else {
            this.setState({ addr1: "", addr2: "", city: "", iso: "", zip: "", })
        }
    }
    validateForm = () => {
        const { t } = this.props
        if (this.state.addr1.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_address_line1') })
        } else if (this.state.city.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_address_city') })
        } else if (this.state.zip.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_address_zip') })
        } else {
            const savedData = Cookies.get("pmx_progress")
            const jsonObj = JSON.parse(savedData)
            const companyInfo = {
                registeredAddress1: (jsonObj.registeredAddress1 === undefined) ? '' : jsonObj.registeredAddress1,
                registeredAddress2: (jsonObj.registeredAddress2 === undefined) ? '' : jsonObj.registeredAddress2,
                registeredCity: (jsonObj.registeredCity === undefined) ? '' : jsonObj.registeredCity,
                registeredAddrIso: (jsonObj.registeredAddrIso === undefined) ? '' : jsonObj.registeredAddrIso,
                registeredZip: (jsonObj.registeredZip === undefined) ? '' : jsonObj.registeredZip,
                addr1: this.state.addr1,
                addr2: this.state.addr2,
                city: this.state.city,
                zip: this.state.zip,
                iso: this.state.iso,
                page: "/idverify"
            }
            Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
            this.setState({ runIdVerify: true })
            init(this.getIdConfig)
        }
    }
    getData = (id) => {
        const config = {
            headers: {
                "X-API-Key": process.env.REACT_APP_GETID_KEY,
                "Content-Type": "application/json",
            },
        }
        axios
            .get(process.env.REACT_APP_GETID_URL+id, config)
            .then((res) => {
                this.sendToServer(
                    res.data.servicesResults.docCheck.extracted.ocr,
                    res.data.application.fields
                )
            })
            .catch((err) => {
                this.setState({ redirect: true })
            })
    }
    sendToServer = (resultData, fieldData) => {
        const savedData = Cookies.get("pmx_progress")
        const jsonObj = JSON.parse(savedData)
        const address = {
            addressLine1: jsonObj.addr1,
            addressLine2: jsonObj.addr2,
            city: jsonObj.city,
            postCode: jsonObj.zip,
            state: jsonObj.city,
            country: jsonObj.iso
        }
        let docType = "ID_CARD"
        let issueDt = ""
        if (findObjectByCategory(resultData, "Document type") === "passport") {
            docType = "PASSPORT"
            issueDt = moment(
                new Date(findObjectByCategory(resultData, "Date of issue"))
            ).format("YYYY-MM-DD")
        } else {
            issueDt =
                findObjectByCategory(fieldData, "Date of issue") === null
                    ? moment(
                        new Date(findObjectByCategory(resultData, "Date of issue"))
                    ).format("YYYY-MM-DD")
                    : moment(
                        new Date(findObjectByCategory(fieldData, "Date of issue"))
                    ).format("YYYY-MM-DD")
        }
        let docNum = (findObjectByCategory(resultData, "Document number") === null) ? findObjectByCategory(resultData, "Personal number") : findObjectByCategory(resultData, "Document number")

        const documentData = {
            documentNumber: docNum,
            issuingCountry: getCountryISO2(
                findObjectByCategory(resultData, "Issue country")
            ),
            documentType: docType,
            documentExpiry: moment(
                new Date(findObjectByCategory(resultData, "Date of expiry"))
            ).format("YYYY-MM-DD"),
            dateOfIssue: issueDt,
        }
        let COB = findObjectByCategory(resultData, "Nationality code")
        if(COB === null){
            COB = findObjectByCategory(resultData, "Nationality")
            if(COB === '??D')
                COB = 'DEU';
        }
        const requestBody = {
            firstName: findObjectByCategory(resultData, "First name"),
            lastName: findObjectByCategory(resultData, "Last name"),
            dateOfBirth: moment(
                new Date(findObjectByCategory(fieldData, "Date of birth"))
            ).format("YYYY-MM-DD"),
            countryOfBirth: getCountryISO2(COB),
            documentData: documentData,
            address: address,
            nfc: false,
        }
        const config = {
            headers: {
                Authorization: `Bearer ${Cookies.get("pmx_token")}`,
                'Accept-Language':Cookies.get("pmx_lang")
            },
        }
        Cookies.remove("pmx_promocode")
                Cookies.remove("pmx_reseller")
                Cookies.remove("pmx_progress")
                this.setState({ redirect: true })
        axiosPersona
            .post("IDVerify", requestBody, config)
            .then((res) => {
                Cookies.remove("pmx_promocode")
                Cookies.remove("pmx_reseller")
                Cookies.remove("pmx_progress")
                this.setState({ redirect: true })
            })
            .catch((err) => {
                this.setState({ redirect: true })
            })
    }
    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            } else {
                return (<Navigate to={this.state.redirectTo} replace={true} />)
            }
        }
        const { t } = this.props
        let screenData = (
            <>
                <div className='mt-4'>
                    <Checkbox clicked={(value) => { this.setAddress(value) }}>{t('LBL_is_same_addr')}</Checkbox>
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr1')}</label>
                    <input type="text" className="form-control" value={this.state.addr1} onChange={(e) => this.setState({ addr1: e.target.value })} />
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr2')}</label>
                    <input type="text" className="form-control" value={this.state.addr2} onChange={(e) => this.setState({ addr2: e.target.value })} />
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr3')}</label>
                    <input type="text" className="form-control" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr4')}</label>
                    <input type="text" className="form-control" value={this.state.zip} onChange={(e) => this.setState({ zip: e.target.value })} />
                </div>
                <div className='mt-4'>
                    <label className="form-label poppins-regular ">{t('LBL_addr_iso')}</label>
                    <Select
                        styles="form-control"
                        clicked={(value) => { this.setState({ iso: value }) }}
                        selectedItem={this.state.iso}
                        options={countryData()}
                    />
                </div>
                <div className='mt-4'>
                    <Checkbox clicked={(value) => { this.setState({ isAuth: value }) }} checked={this.state.isAuth}>{t('LBL_is_authorised')}</Checkbox>
                </div>
                <Button clicked={() => this.validateForm()} btnType={"mt-5 ButtonLogin"} type="submit">{t('BTN_scan_id')}</Button>
            </>
        )
        if (this.state.runIdVerify) {
            screenData = (
                <div className='d-flex flex-row justify-content-center align-items-center align-self-center'>
                    <div id="getid-component" className='mobiFull'>
                        <Loader />
                    </div>
                </div>
            )
        }
        return (
            <Layout>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1" btnName={t('BTN_close')}>
                    <div className="p-3">
                        {this.state.modalErr}
                    </div>
                </ApiError>
                {this.state.showLoader ? (
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                        <Loader />
                    </div>
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                        <div className='W100 mt-4'>
                            <Step active={3} step={3} />
                        </div>
                        <div className='W100 pt-2 ps-4 pe-4'>
                            <h1 className='poppins-extrabold'>{t('IDP_title_h1')}</h1>
                            <p className='poppins-regular mt-4 mb-4'>{t('IDP_info_p')}</p>
                            {screenData}
                        </div>
                    </div>
                )}
            </Layout>
        )
    }
}

export default withTranslation()(Idverify)