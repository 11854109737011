import { LogLevel, BrowserCacheLocation } from '@azure/msal-browser'


export const URL_SEND_EMAIL = (EMAIL, CODE) => `https://www.paymix.eu/email/email.php?email=${EMAIL}&code=${CODE}`
export const URL_REG_EMAIL = (EMAIL, FNAME, LNAME, EXTRA) => `https://www.paymix.eu/email/setContact.php?email=${EMAIL}&fname=${FNAME}&lname=${LNAME}&ref=${EXTRA}`

export const b2cPolicies = {
    names: {
        signUpSignIn: process.env.REACT_APP_B2C_POLICY_SIGNIN,
        forgotPassword: process.env.REACT_APP_B2C_POLICY_FORGOT,
        register: process.env.REACT_APP_B2C_POLICY_SIGNUP
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_B2C_TENENT+process.env.REACT_APP_B2C_POLICY_SIGNIN
        },
        forgotPassword: {
            authority: process.env.REACT_APP_B2C_TENENT+process.env.REACT_APP_B2C_POLICY_FORGOT
        },
        register: {
            authority: process.env.REACT_APP_B2C_TENENT+process.env.REACT_APP_B2C_POLICY_SIGNUP
        },
    },
    authorityDomain: process.env.REACT_APP_B2C_DOMAIN,
    scopes: process.env.REACT_APP_B2C_SCOPE,
}

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENTID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_REDIRECT_BUSINESSURI,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation:BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        // console.info(message)
                        return
                    case LogLevel.Verbose:
                        console.debug(message)
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                    default:
                        return
                }
            },
        },
    },
}