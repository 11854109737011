import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { setGAEvent } from '../../Utils/googleAnalytics'
import {dateStringToDate} from '../../Utils/generalUtil'
import Select, { countryData } from '../../Components/UI/Select/Select'
import ApiError from '../../Components/UI/Alert/ApiError'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Loader from '../../Components/UI/Loader/Loader'
import Step from '../../Components/Step/Step'

class Screen2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            showLoader: true,
            redirect: false,
            gotoHome: false,
            registeredIso: Cookies.get("pmx_iso"),
            registeredDt: "",
            modalErr: "",
            registeredNumber: "",
            vatTax: "",
        }
    }
    // registeredDt: new Date(),
    componentDidMount() {
        if (Cookies.get("pmx_token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        } else {
            setGAEvent("page_view","Busioness Info 2","Step 2")
            const savedData = Cookies.get("pmx_progress")
            if (savedData !== undefined) {
                const jsonObj = JSON.parse(savedData)
                this.setState({
                    showLoader: false,
                    registeredIso: (jsonObj.registeredIso === undefined) ? Cookies.get("pmx_iso") : jsonObj.registeredIso,
                    registeredDt: (jsonObj.registerDate === undefined) ? "" : dateStringToDate(jsonObj.registerDate),
                    registeredNumber: (jsonObj.registeredNumber === undefined) ? "" : jsonObj.registeredNumber,
                    vatTax: (jsonObj.vatTax === undefined) ? "" : jsonObj.vatTax,
                })
            } else {
                this.setState({ showLoader: false })
            }
        }
    }
    validateForm = () => {
        const { t } = this.props
        if (this.state.registeredDt.length===0){
            this.setState({ showErrModal: true, modalErr: t('ERR_reg_date') })
        }else if (this.state.vatTax.length === 0 || this.state.vatTax.length < 3 || this.state.vatTax.length > 20) {
            this.setState({ showErrModal: true, modalErr: t('ERR_vat_tax') })
        } else if (this.state.registeredNumber.length > 0 && (this.state.registeredNumber.length < 3 || this.state.registeredNumber.length > 20)) {
            this.setState({ showErrModal: true, modalErr: t('ERR_regNo_tax') })
        } else {
            const savedData = Cookies.get("pmx_progress")
            const jsonObj = JSON.parse(savedData)
            const companyInfo = {
                companyType: jsonObj.companyType,
                companyName: jsonObj.companyName,
                companyWeb: (jsonObj.companyWeb === undefined) ? "" : jsonObj.companyWeb,
                companyTradeName: (jsonObj.companyTradeName === undefined) ? "" : jsonObj.companyTradeName,
                creditSafeId: (jsonObj.creditSafeId === undefined) ? "N/A" : jsonObj.creditSafeId,
                registeredIso: this.state.registeredIso,
                registeredNumber: this.state.registeredNumber,
                registerDate: this.state.registeredDt,
                vatTax: this.state.vatTax,
                registeredAddress1: (jsonObj.registeredAddress1 === undefined) ? "" : jsonObj.registeredAddress1,
                registeredCity: (jsonObj.registeredCity === undefined) ? "" : jsonObj.registeredCity,
                registeredAddrIso: (jsonObj.registeredAddrIso === undefined) ? "" : jsonObj.registeredAddrIso,
                shareHolder: (jsonObj.shareHolder === undefined) ? "" : jsonObj.shareHolder,
                page: '/business-requirements'
            }
            Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
            this.setState({ redirect: true, redirectTo: "/business-requirements" })
        }
    }
    goBack = () => {
        const savedData = Cookies.get("pmx_progress")
        const jsonObj = JSON.parse(savedData)
        const companyInfo = {
            companyType: jsonObj.companyType,
            companyName: jsonObj.companyName,
            companyWeb: (jsonObj.companyWeb === undefined) ? "" : jsonObj.companyWeb,
            companyTradeName: (jsonObj.companyTradeName === undefined) ? "" : jsonObj.companyTradeName,
            creditSafeId: (jsonObj.creditSafeId === undefined) ? "N/A" : jsonObj.creditSafeId,
            registeredIso: jsonObj.registeredIso,
            registeredNumber: (jsonObj.registeredNumber === undefined) ? "" : jsonObj.registeredNumber,
            registerDate: jsonObj.registerDate,
            vatTax: jsonObj.vatTax,
            mra1: (jsonObj.mra1 === undefined) ? "" : jsonObj.mra1,
            mra2: (jsonObj.mra2 === undefined) ? "" : jsonObj.mra2,
            mra3: (jsonObj.mra3 === undefined) ? "" : jsonObj.mra3,
            bankLocation: (jsonObj.bankLocation === undefined) ? "" : jsonObj.bankLocation,
            bankIban: (jsonObj.bankIban === undefined) ? "" : jsonObj.bankIban,
            naceCode: (jsonObj.naceCode === undefined) ? "" : jsonObj.naceCode,
            mccCode: (jsonObj.mccCode === undefined) ? "" : jsonObj.mccCode,
            needCard: (jsonObj.needCard === undefined) ? "No" : jsonObj.needCard,
            registeredAddress1: (jsonObj.registeredAddr1 === undefined) ? "" : jsonObj.registeredAddr1,
            registeredAddress2: (jsonObj.registeredAddr2 === undefined) ? "" : jsonObj.registeredAddr2,
            registeredCity: (jsonObj.registeredCity === undefined) ? "" : jsonObj.registeredCity,
            registeredZip: (jsonObj.registeredZip === undefined) ? "" : jsonObj.registeredZip,
            registeredAddrIso: (jsonObj.registeredAddrIso === undefined) ? "" : jsonObj.registeredAddrIso,
            operAddress1: (jsonObj.operAddress1 === undefined) ? "" : jsonObj.operAddress1,
            operAddress2: (jsonObj.operAddress2 === undefined) ? "" : jsonObj.operAddress2,
            operCity: (jsonObj.operCity === undefined) ? "" : jsonObj.operCity,
            operZip: (jsonObj.operZip === undefined) ? "" : jsonObj.operZip,
            operAddrIso: (jsonObj.operAddrIso === undefined) ? "" : jsonObj.operAddrIso,
            shareHolder: (jsonObj.shareHolder === undefined) ? "" : jsonObj.shareHolder,
            page: '/business-info'
        }
        Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
        this.setState({ redirect: true, redirectTo: "/business-info" })
    }

    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            } else {
                return (<Navigate to={this.state.redirectTo} replace={true} />)
            }
        }
        const { t } = this.props
        return (
            <Layout>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1" btnName={t('BTN_close')}>
                    <div className="p-3">
                        {this.state.modalErr}
                    </div>
                </ApiError>
                {this.state.showLoader ? (
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                        <Loader />
                    </div>
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                        <div className='W100 mt-4'>
                            <Step active={2} step={2} />
                        </div>
                        <div className='W100 pt-2 ps-4 pe-4'>
                            <h1 className='poppins-extrabold'>{t('BP_title2_h1')}</h1>
                            <p className='poppins-regular mt-4 mb-4'>{t('BP_info2_p')}</p>
                            <div>
                                <label className="form-label poppins-regular ">{t('LBL_regestered_in')}</label>
                                <Select
                                    styles="form-control W550"
                                    clicked={(value) => { this.setState({ registeredIso: value }) }}
                                    selectedItem={this.state.registeredIso}
                                    options={countryData()}
                                />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_regestered_on')}</label>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    onKeyDown={(e) => e.preventDefault()}
                                    className='form-control pointer'
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dropdownMode="scroll"
                                    maxDate={new Date()}
                                    disabledKeyboardNavigation="true"
                                    selected={this.state.registeredDt}
                                    onChange={(date) => this.setState({ registeredDt: date })} />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_regestered_number')}</label>
                                <input type="text" className="form-control" value={this.state.registeredNumber} onChange={(e) => this.setState({ registeredNumber: e.target.value })} />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_vat_tax_number')}</label>
                                <input type="text" className="form-control" value={this.state.vatTax} onChange={(e) => this.setState({ vatTax: e.target.value })} />
                            </div>
                            <Button clicked={() => this.validateForm()} btnType={"mt-5 ButtonLogin"} type="submit">{t('BTN_next')}</Button>
                            <p className='linkBtn poppins-bold pointer' onClick={() => this.goBack()} >{t('LINK_back')}</p>
                        </div>
                    </div>
                )}
            </Layout>
        )
    }
}

export default withTranslation()(Screen2)