import React, { useEffect, useState, useRef } from 'react'
import Cookies from 'js-cookie'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { jwtDecode } from "jwt-decode"

import { b2cPolicies, URL_REG_EMAIL } from '../Config/authConfig'
import {setGAEvent} from '../Utils/googleAnalytics'
import Layout from '../Containers/Layout'
import Step from '../Components/Step/Step'
import Button from '../Components/UI/Button/Button'
import Loader from '../Components/UI/Loader/Loader'
import logo from '../Assets/softPos-icon.svg'
import fullLogo from '../Assets/logo.svg'
import check from '../Assets/Success.svg'

const SuccessStep = (props) => {
    const { instance } = useMsal()
    const [iframeSrc, setIframeSrc] = useState('');
    const iframeRef = useRef(null);
    const navigate = useNavigate()
    const [title1, setTitile1] = useState("")
    const [title2, setTitile2] = useState("")
    const [info, setInfo] = useState("")
    const [buttonTitle, setButtonTitle] = useState("")
    const [loader, setLoader] = useState(true)
    const [reLogin, setReLogin] = useState(false)
    const { t } = useTranslation()
    useEffect(() => {
        switch (props.type) {
            case 2:
                setGAEvent("page_view","Business Info Success","Profile in Persona")
                setTitile1(t('SSP_type2_title1'))
                setTitile2(t('SSP_type2_title2'))
                setInfo(t('SSP_type2_info'))
                setButtonTitle(t('BTN_business_id'))
                break
            case 3:
                setGAEvent("page_view","Profile Success","Profile Fully created")
                setTitile1(t('SSP_type3_title1'))
                setTitile2(t('SSP_type3_title2'))
                setInfo(t('SSP_type3_info'))
                break
            default:
                setGAEvent("page_view","Account Created","Returned from Azure")
                setTitile1(t('SSP_type1_title1'))
                setTitile2(t('SSP_type1_title2'))
                setInfo(t('SSP_type1_info'))
                setButtonTitle(t('BTN_business_info'))
                break
        }
        async function getToken() {
            let request = {
                scopes: [b2cPolicies.scopes],
            }
            await instance.acquireTokenSilent(request).then(tokenResponse => {
                if (tokenResponse.accessToken.length > 0) {
                    Cookies.set("pmx_token", tokenResponse.accessToken, { expires: 1 })
                }
                setLoader(false)
            }).catch(async (err) => {
                if(err.errorMessage.includes("AADB2C90077"))
                    setReLogin(true)
                setLoader(false)
            })
        }
        const handleRedirect = async () => {
            try {
                const response = await instance.handleRedirectPromise();
                if (response) {
                    const accessToken = response.idToken;
                    const decodedToken = jwtDecode(accessToken);
                    const eml = decodedToken?.email;
                    const fnm = decodedToken?.given_name;
                    const lnm = decodedToken?.family_name;
                    let extra = ""
                    if (Cookies.get("pmx_reseller") !== undefined && Cookies.get("pmx_reseller") !== null) {
                        extra = "Referral:"+ Cookies.get("pmx_reseller")+ ", "
                    }
                    extra += decodedToken?.extension_AgreedtoTerms+", "+decodedToken?.extension_AgreetoPrivacy;
                    if(decodedToken?.extension_AgreeMarketing!==undefined)
                        extra +=", "+decodedToken?.extension_AgreeMarketing;
                    setIframeSrc(URL_REG_EMAIL(eml, fnm, lnm, extra))
                    getToken();
                }
            } catch (err) {
                window.location.href = window.location.href+"";
                console.error("Redirect handling error:", err);
            }
        };
        if(props.type===1){
            setTimeout(() => {
                handleRedirect()
            }, 5000);
        }else{
            getToken() 
        }
    }, [props.type, t, instance]);
    const doNavigate = () => {
        switch (props.type) {
            case 2:
                navigate("/idverify")
                break;
            default:
                if(reLogin){
                    doLogin();
                }else{
                    navigate("/business-info")
                }
                break;
        }
    }
    const doLogin = async () => {
        try {
            await instance.loginRedirect({redirectUri:process.env.REACT_APP_REDIRECT_BUSINESSURI})
            setLoader(true)
        } catch (err) {
            alert(err)
        }
    }
    return (
        <Layout>
            <iframe ref={iframeRef} src={iframeSrc} width="0px" height="0px" title="hello" />
            {loader ? (
                <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                    <Loader />
                </div>
            ) : (
                <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                    {(props.type === 3) ? (
                        <>
                            <div className='W100 mt-4'>
                                <Step active={4} step={3} />
                            </div>
                            <div className='pt-2 ps-4 pe-4 justify-content-center align-items-center align-self-center text-center mt-4'>
                                <center><img src={fullLogo} alt='Success' title="Success" className='thankYouLogo mt-4' /></center>
                            </div>
                            <div className='pt-2 ps-4 pe-4 justify-content-center align-items-center align-self-center text-center mt-4'>
                            <center><img src={check} alt='Success' title="Success" className='checkImg' /></center>
                                <h2 className='poppins-extrabold'>{title1}</h2>
                                <h4 className='poppins-bold mt-4'>{title2}</h4>
                                <p className='mt-3 mb-3'>{info}</p>
                            </div>
                        </>
                    ) : (
                        <>  
                            <div className='W100 mt-4'>
                                {props.type === 2 ?
                                    (<Step active={3} step={3} />) :
                                    (<Step active={2} step={2} />)
                                }
                            </div>
                            <div className='pt-2 ps-4 pe-4 justify-content-center align-items-center align-self-center text-center'>
                                <center><img src={check} alt='Success' title="Success" className='checkImg' /></center>
                                <h2 className='poppins-extrabold'>{title1}</h2>
                                <h4 className='poppins-bold mt-4'>{title2}</h4>
                                <p className='mt-3 mb-3'>{info}</p>
                                <center><img src={logo} alt='SoftPOS' title="SoftPOS" className='logoIcon mt-4' /></center>
                            </div>
                            <div className='W100 pt-2 ps-4 pe-4 justify-content-center align-items-center align-self-center mt-3'>
                                <Button clicked={() => doNavigate()} btnType={"mt-4 ButtonLogin"} type="submit">
                                    {buttonTitle}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </Layout>
    )
}
export default SuccessStep