import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { jwtDecode } from "jwt-decode"
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import axios from 'axios'

import Select, { arrayObjToObj } from '../../Components/UI/Select/Select'
import { URL_SEND_EMAIL } from '../../Config/authConfig'
import { getCompanyType, formatDate } from '../../Utils/generalUtil'
import { setGAEvent } from '../../Utils/googleAnalytics'

import axiosCreditSafe from '../../Utils/creditSafeAxios'
import ApiError from '../../Components/UI/Alert/ApiError'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Loader from '../../Components/UI/Loader/Loader'
import Modal from '../../Components/UI/Modal/Modal'
import Step from '../../Components/Step/Step'
import Logo from '../../Assets/softPos-icon.svg'

class Screen1 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            showModal:false,
            showLoader: true,
            showLoader1: false,
            redirect: false,
            gotoHome: false,
            modalErr: "",
            companyType: 0,
            companyName: "",
            companyWeb: "",
            companyTradeName: "",
            redirectTo: "",
            creditSafeJWT: "",
            companyList: [],
            csCompanyList: []
        }
    }

    componentDidMount() {
        if (Cookies.get("pmx_token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        } else {
            setGAEvent("page_view","Busioness Info 1","Step 1")
            const { t } = this.props
            const decodedToken = jwtDecode(Cookies.get("pmx_token"));
            const eml = decodedToken?.emails[0];
            const savedData = Cookies.get("pmx_progress")
            if (Cookies.get("pmx_promoCode") !== undefined && Cookies.get("pmx_promoCode") !== null) {
                axios.get(URL_SEND_EMAIL(eml, Cookies.get("pmx_promoCode") )).then(res => { }).catch(err => { })
            }
            const arrayList = getCompanyType(t)
            if (savedData !== undefined) {
                const jsonObj = JSON.parse(savedData)
                if (jsonObj.page !== '/business-info') {
                    this.setState({
                        redirect: true,
                        redirectTo: (jsonObj.page === undefined) ? "/" : jsonObj.page,
                    })
                } else {
                    this.setState({
                        companyList: arrayList,
                        showLoader: false,
                        redirectTo: (jsonObj.page === undefined) ? "/" : jsonObj.page,
                        companyType: (jsonObj.companyType === undefined) ? "0" : jsonObj.companyType,
                        companyName: (jsonObj.companyName === undefined) ? "" : jsonObj.companyName,
                        companyWeb: (jsonObj.companyWeb === undefined) ? "" : jsonObj.companyWeb,
                        companyTradeName: (jsonObj.companyTradeName === undefined) ? "" : jsonObj.companyTradeName,
                    })
                }
            } else {
                this.setState({ companyList: arrayList, showLoader: false })
            }
        }
    }
    validateForm = () => {
        const { t } = this.props
        if (this.state.companyName.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_company_name') })
        } else if (this.state.companyType < 1) {
            this.setState({ showErrModal: true, modalErr: t('ERR_company_type') })
        } else {
            const companyInfo = {
                companyType: this.state.companyType,
                companyName: this.state.companyName,
                companyWeb: this.state.companyWeb,
                companyTradeName: this.state.companyTradeName,
                page: '/business-profile'
            }
            Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
            this.setState({ showLoader1: true, redirectTo: "/business-profile" })
            this.getCreditSafeAuth()
        }
    }

    getCreditSafeAuth = () => {
        const bodyData = {
            "username": process.env.REACT_APP_CREDIT_SAFE_UNM,
            "password": process.env.REACT_APP_CREDIT_SAFE_PWD,
        }
        axiosCreditSafe.post("authenticate", bodyData, null)
            .then(res => {
                this.getAllCompanies(res.data.token)
            })
            .catch(err => {
                this.setState({ redirect: true })
            })
    }

    getCompany = (companyId) => {
        this.setState({ showLoader1: true })
        const config = {
            headers: {
                Authorization: `Bearer ${this.state.creditSafeJWT}`
            }
        }
        let URL = "companies/" + companyId + ((Cookies.get("REGION") === 'DE') ? "?customData=de_reason_code::1" : "")
        axiosCreditSafe.get(URL, config)
            .then(res => {
                const shareHolder = []
                if(res.data.report.shareCapitalStructure.shareHolders.length>0){
                    const shareHolderInfo = res.data.report.shareCapitalStructure.shareHolders
                    shareHolderInfo.map((item) =>{
                        const fullName = item.name.split(" ")
                        const lastName = fullName.pop()
                        const firstName = fullName.join(" ")
                        let tempObject = {
                            firstName: firstName.trim(),
                            lastName: lastName.trim(),
                            share: item.percentSharesHeld
                        }
                        shareHolder.push(tempObject)
                        return null;
                    });
                }
                const companyInfo = {
                    companyType: this.state.companyType,
                    companyName: res.data.report.companySummary.businessName,
                    companyWeb: this.state.companyWeb,
                    companyTradeName: this.state.companyTradeName,
                    creditSafeId: res.data.companyId,
                    registeredIso: res.data.report.companySummary.country,
                    registeredNumber: res.data.report.companySummary.companyRegistrationNumber,
                    registerDate: new Date(formatDate(res.data.report.companyIdentification.basicInformation.companyRegistrationDate)),
                    vatTax: res.data.report.companyIdentification.basicInformation.vatRegistrationNumber,
                    registeredAddress1: res.data.report.contactInformation.mainAddress.street,
                    registeredCity: res.data.report.contactInformation.mainAddress.city,
                    registeredAddrIso: res.data.report.contactInformation.mainAddress.country,
                    shareHolder:shareHolder,
                    page: '/business-profile'
                }
                Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
                this.setState({ redirect: true })
            })
            .catch(err => {
                this.setState({ redirect: true })
            })
    }

    getAllCompanies = (token) => {
        this.setState({ creditSafeJWT: token })
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        let URL = "companies?name=" + encodeURI(this.state.companyName) + ((Cookies.get("pmx_iso") === 'DE') ? '&countries=DE' : '&countries=MT')
        axiosCreditSafe.get(URL, config)
            .then(res => {
                if (res.data.companies.length > 0) {
                    this.setState({ csCompanyList: res.data.companies, showModal: true, showLoader1: false })
                } else {
                    this.setState({ redirect: true })
                }
            })
            .catch(err => {
                this.setState({ redirect: true })
            })
    }

    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            } else {
                return (<Navigate to={this.state.redirectTo} replace={true} />)
            }
        }
        const { t } = this.props
        let modalData = null
        if (this.state.csCompanyList.length > 0) {
            modalData = (
                <div className="d-flex flex-column justify-content-center">
                    <div className='selectCompanyModal'>
                        <table className="table table-bordered table-hover hideMobi" cellPadding={10} cellSpacing={10}>
                            <thead>
                                <tr>
                                    <th>{t('TBL_reg_number')}</th>
                                    <th>{t('TBL_company_name')}</th>
                                </tr>
                            </thead><tbody>
                                {this.state.csCompanyList.map((el, index) => (
                                    <tr key={index} className='pointer' onClick={() => this.getCompany(el.id)}>
                                        <td>{el.regNo}</td>
                                        <td>{el.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <center><Button clicked={() => this.setState({ showModal: false, screen: 2, btnBackDisable: false, redirect: true,redirectTo:'/business-profile'  })} btnType={"mt-5 mb-2 ButtonLogin"}>{t('BTN_skip')}</Button></center>
                    </div>
                </div>
            )
        }
        if (this.state.showLoader1) {
            return (
                <Layout>
                    <div className="d-flex flex-column justify-content-between align-items-center align-self-center H100">
                        <div className='W100 mt-4'>
                            <Step active={2} step={2} />
                        </div>
                        <div className='text-center'>
                            <center><Loader /></center>
                            <p className='mt-3 loaderInfo poppins-regular'>{t('LOADER_credit_safe_info1')}</p>
                            <p className='mt-2 loaderInfo poppins-bold'>{t('LOADER_credit_safe_info2')}</p>
                        </div>
                        <div className='mb-5'>
                            <img src={Logo} alt='Paymix SoftPOS' title='Paymix SoftPOS' />
                        </div>
                    </div>
                </Layout>
            )
        }
        return (
            <Layout>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1" btnName={t('BTN_close')}>
                    <div className="p-3">
                        {this.state.modalErr}
                    </div>
                </ApiError>
                <Modal show={this.state.showModal} modalClosed={() => null} modalType="Modal1">
                    <div className="p-3">
                        <h3 className="modalTitle mb-4 text-center">{t('MODAL_select_company')}</h3>
                        {modalData}
                    </div>
                </Modal>
                {this.state.showLoader ? (
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                        <Loader />
                    </div>
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                        <div className='W100 mt-4'>
                            <Step active={2} step={2} />
                        </div>
                        <div className='W100 pt-2 ps-4 pe-4'>
                            <h1 className='poppins-extrabold'>{t('BP_title1_h1')}</h1>
                            <p className='poppins-regular mt-4 mb-4'>{t('BP_info1_p')}</p>
                            <div>
                                <label className="form-label poppins-regular ">{t('LBL_company_name')}</label>
                                <input type="text" className="form-control" value={this.state.companyName} onChange={(e) => this.setState({ companyName: e.target.value })} />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_company_type')}</label>
                                <Select
                                    styles="form-control"
                                    clicked={(value) => { this.setState({ companyType: value }) }}
                                    selectedItem={this.state.companyType}
                                    options={arrayObjToObj(this.state.companyList)}
                                />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_trading_as')}</label>
                                <input type="text" className="form-control" value={this.state.companyTradeName} onChange={(e) => this.setState({ companyTradeName: e.target.value })} />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_website')}</label>
                                <input type="text" className="form-control" value={this.state.companyWeb} onChange={(e) => this.setState({ companyWeb: e.target.value })} />
                            </div>
                            <Button clicked={() => this.validateForm()} btnType={"mt-5 ButtonLogin"} type="submit">{t('BTN_next')}</Button>
                        </div>
                    </div>
                )}
            </Layout>
        )
    }
}

export default withTranslation()(Screen1)