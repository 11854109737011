import React from 'react'

const Layout = (props) => {
    return (
        <div className='fullHeight'>
            <section className='mainContent'>
                {props.children}
            </section>
        </div>
    )
}

export default Layout